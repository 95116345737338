.product-container {
    &.vd-product-style-default {
        .vd-product {
            overflow: auto;
            margin-bottom: 20px;
            border: 1px solid #ddd;
            h4 {
                font-weight: bold;
            }
            .caption {
                min-height: 180px;
                padding: 0 20px;
            }
            .image {
                float: none;
                text-align: center;
                a {
                    display: block;
                    &:hover {
                        opacity: 0.8;
                    }
                }
                img {
                    margin-right: auto;
                    margin-left: auto;
                }
            }
            .rating {
                padding-bottom: 10px;
                .fa-stack {
                    font-size: 8px;
                }
                .fa-star-o {
                    color: #999;
                    font-size: 15px;
                }
                .fa-star {
                    color: #FC0;
                    font-size: 15px;
                }
                .fa-star+.fa-star-o {
                    color: #E69500;
                }
            }
            h2.price {
                margin: 0;
            }
            .price {
                color: #444;
                .price-new {
                    font-weight: 600;
                }
                .price-old {
                    margin-left: 10px;
                    text-decoration: line-through;
                    color: #999;
                }
                .price-tax {
                    display: block;
                    color: #999;
                    font-size: 12px;
                }
            }
            .button-group {
                overflow: auto;
                border-top: 1px solid #ddd;
                background-color: #eee;
                button {
                    display: inline-block;
                    float: left;
                    width: 60%;
                    text-align: center;
                    text-transform: uppercase;
                    color: #888;
                    border: none;
                    background-color: #eee;
                    font-weight: bold;
                    line-height: 38px;
                    >span {
                        display: inline!important;
                    }
                    &+button {
                        width: 20%;
                        border-left: 1px solid #ddd;
                    }
                    &:hover {
                        cursor: pointer;
                        text-decoration: none;
                        color: #444;
                        background-color: #ddd;
                    }
                }
            }
        }
    }
}